.modal-icon {
  position: absolute;
  color: saturate(darken(@brand-info, 10%), 40%);
  background-color: lighten(@brand-info, 26%);
  top: -20px;
  margin-left: -20px;
  left: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 42px;
  font-size: 18px;
  border-radius: 50%;

  &.primary {
    color: #fff;
    background-color: @brand-primary;
  }

  &.accent {
    color: #fff;
    background-color: @brand-jira;
  }
}

.modal {
  &.sidebar-modal {
    .modal-dialog {
      position: absolute;
      right: 0;
      width: 800px;
      top: 0;
      min-height: 100%;
      margin: 0;
      padding: 0;

      .modal-content {
        height: 100%;
        border-radius: 0;
        border: 0;
        min-height: 100vh;

        h2 {
          margin-bottom: 40px;
        }
      }

      @media all and (max-width: 1500px) {
        width: 50%;
      }
    }

    &.no-padding {
      .modal-content {
        padding: 0;
      }
    }

    &.sidebar-modal {
      &.small {
        width: 250px;
      }
    }
  }

  &.goal-sidebar {
    .modal-dialog {
      width: 800px;
    }

    @media all and (max-width: 1200px) {
      .modal-dialog {
        width: 800px;
        max-width: 95%;

        .modal-content {
          padding: 40px 80px;
        }
      }
    }
  }

  &.auto-width {
    .modal-dialog {
      width: auto;
    }

    @media all and (max-width: 1200px) {
      .modal-dialog {
        width: auto;
        max-width: 95%;

        .modal-content {
          padding: 0;
        }
      }
    }
  }

  &.group-details-sidebar {
    .modal-dialog {
      width: 870px !important;
    }
  }

  &.profile-sidebar {
    .modal-dialog {
      width: 840px !important;
    }
  }

  &.user-profile-sidebar {
    .modal-dialog {
      max-width: 550px !important;
    }
  }

  &.group-details-sidebar,
  &.goal-sidebar,
  &.profile-sidebar,
  &.user-profile-sidebar {
    .modal-dialog {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      height: 100%;

      .modal-content {
        height: 100%;
        border-radius: 0;
        border: 0;
        padding: 0;
        overflow-x: hidden;
      }
    }
  }

  &.goal-sidebar {
    .modal-dialog {
      position: absolute;
      height: unset;
      bottom: unset;

      .modal-content {
        min-height: 100vh;
        overflow-x: visible;
      }
    }
  }

  &.widget-browser {
    .modal-dialog {
      .modal-content {
        padding: 40px 80px 0px 80px;

        &::after {
          content: "";
          display: block;
          padding-bottom: 100px;
        }
      }
    }
  }

  &.overflow-hidden {
    .modal-dialog {
      .modal-content {
        height: 100vh;
        overflow-y: hidden;
      }
    }
  }

  &.create-form {
    .modal-dialog {
      width: 662px !important;
    }
  }

  &.create-form {
    &.create-goal {
      .modal-dialog {
        width: fit-content !important;

        .modal-content {
          background-color: transparent;
        }
      }
    }
  }

  &.session-form {
    .modal-dialog {
      width: 678px !important;
    }
  }

  &.how-to-start {
    .modal-dialog {
      width: 800px;
    }
  }

  &.create-first-goal {
    &.small {
      .modal-dialog {
        width: 700px;

        .modal-content {
          min-height: 250px;
        }
      }
    }

    &.wide {
      .modal-dialog {
        width: 900px;
      }
    }
  }

  &.clear-modal-size-and-spacing {
    .modal-dialog {
      width: fit-content !important;
      display: flex;
      justify-content: center;

      .modal-content {
        width: auto !important;
        padding: 0 !important;
        background-color: transparent;
        box-shadow: unset;
      }
    }
  }
}

.modal-backdrop {
  background: rgba(39, 52, 59, 0);
}

.modal-backdrop.in {
  background: rgba(39, 52, 59, 0.2);
  opacity: 1;

  &.dark-backdrop {
    background: fade(@brand-dark, 38%);
  }
}

@keyframes backdrop {
  0% {
    background: rgba(39, 52, 59, 0);
  }

  100% {
    background: rgba(39, 52, 59, 0.15);
  }
}

.modal {
  .modal-dialog {
    width: 1200px;

    &.modal-sm {
      width: 600px;
    }

    &.modal-lg {
      .modal-body {
        float: left;
        width: 1200px;
      }
    }

    // MODAL HEADER
    .modal-header {
      background: @brand-dark;
      margin-top: -40px;
      margin-left: -40px;
      margin-right: -40px;
      padding: 20px 40px;
      position: relative;
      margin-bottom: 40px;

      &.img {
        padding-left: 100px;

        img {
          position: absolute;
          left: 40px;
          width: 40px;
          top: 20px;
        }
      }

      // modal title
      h1 {
        color: #fff;
        font-size: 16px;
        font-weight: @light;
        padding: 0;
        margin: 0 0 5px 0;

        em {
          font-style: normal;
          color: fade(#fff, 70%);
        }
      }

      p {
        color: rgba(255, 255, 255, 0.5);
      }

      //modal subtitle
      h4 {
        font-size: 13px;
        color: fade(#fff, 40%);
        margin: 0 0 5px 0;
        padding: 0;
      }

      // modal's close button
      .glyphicon-remove {
        position: absolute;
        top: 30px;
        right: 0px;
        color: #666;

        &:hover {
          color: #fff;
          cursor: pointer;
        }
      }

      .x {
        font-size: 24px;
        color: fade(#fff, 50%);
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -15px;

        &:hover {
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .modal-body.body + .modal-footer {
      padding-top: 0px;
      border-top: 0;
    }

    // MODAL FOOTER
    .modal-footer {
      clear: both;
      float: left;
      width: 100%;
      text-align: left;
      border-top: 1px solid #eee;
      margin: 30px 0 0 0;
      padding: 15px 0 0 0;

      a.back {
        font-size: 16px;
        display: inline-block;
        font-weight: @light;
        color: #999;
        cursor: pointer;
        line-height: 35px;

        .icon {
          margin-right: 20px;
        }

        &:hover {
          color: @brand-primary;
        }
      }
    }

    .modal-body {
      padding: 0;

      &.body {
        float: left;
        background: @brand-light;
        width: 1200px;
        margin-left: -40px;
        margin-top: -40px;
        margin-bottom: -40px;
        padding: 40px;
      }
    }

    //MODAL CONTENT
    .modal-content {
      position: relative;
      padding: @modal-content-padding;
      border-radius: 5px;
      min-height: 360px;
      float: left;
      border: 0;
      width: 100%;
      box-shadow: 0 5px 10px rgba(0, 85, 120, 0.2);

      // Animation helper for transition between the steps of a wizard
      .step {
        position: relative;
        opacity: 0;
        .transition(0.3s);

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  &.modal-small {
    .modal-dialog {
      width: 400px;
    }
  }

  &.without-padding {
    .modal-dialog {
      .modal-content {
        padding: 0px;
      }
    }
  }

  &.no-transform {
    .modal-dialog {
      transform: none;
    }
  }

  &.no-min-height {
    .modal-dialog {
      .modal-content {
        min-height: inherit;
      }
    }
  }

  &.medium {
    .modal-dialog {
      width: 800px;
    }
  }

  &.w-750px {
    .modal-dialog {
      width: 750px;
    }
  }

  &.w-1175px {
    .modal-dialog {
      width: 1175px;
    }
  }

  &.vertical-offset-45px {
    .modal-dialog {
      margin: 45px auto;
    }
  }

  &.medium-small {
    .modal-dialog {
      width: 500px;
    }

    &.assignees-modal {
      .modal-dialog {
        width: 600px;
      }
    }

    &.tag-editor-modal {
      .modal-content {
        min-height: auto;
      }
    }

    &.merge-tags-modal {
      .modal-content {
        min-height: auto;
        width: 640px;
        overflow: visible;
      }
    }
  }

  &.medium-large {
    .modal-dialog {
      width: 600px;
    }
  }

  &.medium-x-large {
    .modal-dialog {
      width: @modal-medium-x-large-width;
    }
  }

  &.large {
    .modal-dialog {
      width: 1060px;
    }
  }

  &.large-small {
    .modal-dialog {
      width: 900px;
    }
  }

  &.xl-large {
    .modal-dialog {
      width: 1360px;
    }
  }

  &.large-medium {
    .modal-dialog {
      width: 1000px;
    }
  }

  &.padding-38px-50px {
    .modal-content {
      padding: 38px 50px;
    }
  }

  &.vertical-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  &.height-small {
    .modal-dialog {
      .modal-content {
        min-height: 200px;
      }
    }
  }

  &.d-flex {
    .modal-dialog {
      .modal-content {
        &:extend(.d-flex);
      }
    }
  }
}

@media all and (max-width: 1240px) {
  .modal {
    .modal-dialog {
      width: 90%;
    }
  }
}
